<template>
  <div class="image-swiper">
    <section ref="imageWindow" class="window">
      <div
        class="images"
        :style="{ transform: `translateX(${-translateDistance}px)` }"
      >
        <div :key="i" v-for="(image, i) in images" class="image-item">
          <div class="image-wrapper">
            <img
              :src="
                image ||
                'https://i.pinimg.com/550x/94/96/cb/9496cb7a85bc3300253c3ff311a5e819.jpg'
              "
            />
          </div>
        </div>
      </div>
    </section>
    <section class="indicators">
      <button class="previous" @click="toPrevious">
        <img src="@/images/icons/buttonLeftWhite.svg" />
      </button>
      <button class="next" @click="toNext">
        <img src="@/images/icons/buttonRightWhite.svg" />
      </button>
    </section>
  </div>
</template>

<script>
import { ref, computed, toRef } from "vue";
export default {
  name: "ProductImages",
  components: {},
  props: {
    images: Array,
  },
  setup(props) {
    const images = toRef(props, 'images');
    const currentIndex = ref(0);
    const imageWindow = ref(null);
    const oneSlideDistance = computed(() => {
      if (!imageWindow.value) return 0;
      return imageWindow.value.offsetWidth;
    });

    const translateDistance = computed(() => {
      return oneSlideDistance.value * currentIndex.value;
    });

    const toPrevious = () => {
      if (currentIndex.value === 0) return;
      currentIndex.value--;
    };

    const toNext = () => {
      if (currentIndex.value === images.length - 1) return;
      currentIndex.value++;
    };

    return {
      currentIndex,
      imageWindow,
      translateDistance,
      toPrevious,
      toNext,
      images,
    };
  },
};
</script>

<style lang="scss" scoped>
.image-swiper {
  position: relative;

  .window {
    width: 100%;
    overflow: hidden;
  }

  .indicators {
    position: absolute;
    bottom: 25px;
    right: -35px;
    transform: translateX(100%);
  }
}

.images {
  display: flex;
  width: 100%;
  transition: 0.4s transform;

  .image-item {
    flex: 0 0 100%;
  }
}

.image-wrapper {
  width: 100%;
  padding-bottom: 100% * 4 / 3;
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.indicators {
  display: flex;
  & > button {
    width: 50px;
    height: 50px;
    border: 1px solid $white;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    img {
      width: 100%;
    }

    &:last-child {
      border-left: none;
    }

    @include RWD($mediumLaptop) {
      width: 40px;
      height: 40px;
    }
  }
}
</style>