<template>
  <div class="product-page">
    <h2 class="title">PRODUCT</h2>
    <section class="page-content">
      <template v-if="productList.length">
        <InfiniteCarousel
          type="product"
          :dataList="productList"
          :autoPlaySec="3000"
          :onClickCard="openLightBox"
          @slide-clicked="openLightBox"
        />
      </template>
    </section>
  </div>
  <EachProduct
    v-if="showLightBox && productList.length"
    :currentIndex="currentOpenIndex"
    :totalLength="productList.length"
    :onClose="closeLightBox"
    :toPrevious="toPrevious"
    :toNext="toNext"
    :productData="productList[currentOpenIndex]"
  />
</template>

<script>
import { ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import InfiniteCarousel from "@/components/atoms/InfiniteCarousel";
import EachProduct from "./EachProduct";
export default {
  name: "Product",
  components: { InfiniteCarousel, EachProduct },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const currentOpenIndex = ref(null);
    const showLightBox = ref(false);
    const productList = computed(() => store.state.products);
    const loading = computed(() => store.state.loading);

    const openLightBox = (id) => {
      console.log("light box triggered", id);
      router.replace(`/products/${id}`);
    };

    const closeLightBox = () => {
      router.replace(`/products`);

      currentOpenIndex.value = null;
      showLightBox.value = false;
    };

    const toNext = () => {
      let index = Number(currentOpenIndex.value) + 1;
      if (index === productList.value.length) {
        index = 0;
      }

      openLightBox(index);
    };

    const toPrevious = () => {
      let index = Number(currentOpenIndex.value) - 1;
      if (index < 0) {
        index = productList.value.length - 1;
      }

      openLightBox(index);
    };

    watch(
      () => route.params.id,
      (id) => {
        if (id) {
          currentOpenIndex.value = +id;
          showLightBox.value = true;
        }
      },
      { immediate: true }
    );

    return {
      loading,
      productList,
      showLightBox,
      closeLightBox,
      openLightBox,
      currentOpenIndex,
      toNext,
      toPrevious,
    };
  },
};
</script>

<style lang="scss" scoped>
.product-page {
  @include page;

  .title {
    @include page-title($white);
  }

  .page-content {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }
}
</style>
