<template>
  <div
    ref="carousel"
    class="event-carousel"
    :style="{ height: `${cHeight}px` }"
  >
    <div class="items" :class="{ 'slide-active': turnOnSlide, [type]: true }">
      <div
        :key="item.id"
        v-for="item in renderList"
        class="item"
        @click="onClickCard(item.id)"
        @mouseenter="stopAutoPlay"
        @mouseleave="startAutoPlay"
      >
        <div class="image-wrapper">
          <img :src="item.image" />
        </div>
        <div class="info">
          <span>{{ item.name }}</span>
          <span>{{ item.name_en }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, onMounted, onUnmounted, ref, watch } from "vue";
export default {
  name: "InfiniteCarousel-Desktop",
  props: {
    dataList: Array,
    type: "product" | "event",
    autoPlaySec: Number,
    onClickCard: Function,
  },
  setup(props) {
    const { type = "event", autoPlaySec, onClickCard } = props;
    const media = inject("media");
    const currentIndex = ref(0);
    const carousel = ref(null);
    const windowWidth = ref(window.innerWidth);
    const list = ref(props.dataList);
    const turnOnSlide = ref(false);
    const interval = ref(null);

    const getIndex = (index, arrayLength) => {
      if (index < 0) {
        return arrayLength + index;
      }
      if (index > arrayLength - 1) {
        return index - arrayLength;
      }

      return index;
    };

    const renderList = computed(() => {
      let result = [];

      if (type === "product") {
        for (let i = -1; i < 3; i++) {
          const index = getIndex(currentIndex.value + i, list.value.length);
          result[i + 1] = list.value[getIndex(index)];
        }
      } else {
        if (list.value.length === 0) return result;

        for (let i = -2; i < 4; i++) {
          const index = getIndex(currentIndex.value + i, list.value.length);
          result[i + 2] = list.value[getIndex(index)];
        }
      }
      return result;
    });

    const cHeight = computed(() => {
      if (type === "product") {
        return (windowWidth.value * 0.4 * 2) / 3;
      }
      return (windowWidth.value * 0.36 * 9) / 16;
    });

    const autoPlay = () => {
      turnOnSlide.value = true;
    };

    const startAutoPlay = () => {
      interval.value = window.setInterval(() => {
        autoPlay();
      }, autoPlaySec);
    };

    const stopAutoPlay = () => {
      window.clearInterval(interval.value);
    };

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
      startAutoPlay();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
      stopAutoPlay();
    });

    watch(turnOnSlide, (t) => {
      let timeout;
      if (timeout) {
        clearTimeout(timeout);
      }

      if (t) {
        timeout = setTimeout(() => {
          currentIndex.value = getIndex(
            currentIndex.value + 1,
            list.value.length
          );
          turnOnSlide.value = false;
        }, 400);
      }
    });

    return {
      type,
      media,
      cHeight,
      carousel,
      renderList,
      turnOnSlide,
      currentIndex,
      startAutoPlay,
      stopAutoPlay,
      onClickCard,
    };
  },
};
</script>

<style lang="scss" scoped>
.event-carousel {
  width: 100%;
  overflow: hidden;

  .items {
    display: flex;
    position: relative;
    height: 100%;

    .item {
      display: flex;
      margin: auto 0;
      cursor: pointer;

      .image-wrapper {
        width: 100%;
        height: 0;
        position: relative;
        margin: auto 0;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &.event {
      gap: 3%;
      left: calc(-14%);

      .item {
        flex: 0 0 20%;
        position: relative;

        &:nth-child(3) {
          flex: 0 0 36%;
        }

        .image-wrapper {
          padding-bottom: calc(100% * 9 / 16);
        }

        .info {
          display: none;
        }
      }

      &.slide-active {
        transition: 0.4s left;
        left: calc(-37%);

        .item {
          transition: 0.4s all;

          &:nth-child(3) {
            flex: 0 0 20%;
          }
          &:nth-child(4) {
            flex: 0 0 36%;
          }
        }
      }
    }

    &.product {
      gap: 7%;
      left: calc(-3%);

      .item {
        flex: 0 0 26%;
        position: relative;

        .image-wrapper {
          padding-bottom: calc(100% * 2 / 3);
        }

        .info {
          display: none;
          position: absolute;
          padding: 30px 36px;
          width: 100%;
          bottom: 0;
          background-color: rgba(255, 255, 255, 0.8);

          @include RWD($mediumLaptop) {
            padding: 15px 20px;
          }

          & > span {
            display: block;
            color: $black;
            &:first-child {
              font-family: "Zpix";
              font-size: 24px;
              font-weight: 500;
              line-height: 40px;
              margin-bottom: 10px;

              @include RWD($mediumLaptop) {
                font-size: 18px;
                line-height: 34px;
                margin-bottom: 5px;
              }
            }

            &:last-child {
              font-family: "Red Rose";
              font-size: 40px;
              font-weight: 400;
              line-height: 50px;
              letter-spacing: 0.04em;

              @include RWD($mediumLaptop) {
                font-size: 34px;
                line-height: 40px;
              }
            }
          }
        }

        &:nth-child(2) {
          flex: 0 0 40%;

          .info {
            display: block;
          }
        }
      }

      &.slide-active {
        transition: 0.4s left;
        left: calc(-36%);

        .item {
          transition: 0.4s flex;

          &:nth-child(2) {
            flex: 0 0 26%;

            .info {
              display: none;
            }
          }
          &:nth-child(3) {
            flex: 0 0 40%;

            .info {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>