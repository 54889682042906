<template>
  <div class="each-product-lightbox mobile">
    <button @click="onClose" class="close-button">
      <img src="@/images/icons/close-icon.svg" />
    </button>
    <div class="box">
      <div class="content">
        <section class="numbers">
          <span class="number">{{
            currentIndex + 1 > 9 ? currentIndex : `0${currentIndex + 1}`
          }}</span>
          <span class="dash" />
          <span class="number">{{
            totalLength > 9 ? totalLength : `0${totalLength}`
          }}</span>
        </section>
        <img src="@/images/backgrounds/vector-top.png" />
        <div class="main-content">
          <div class="top">
            <div class="image-wrapper">
              <img :src="data.images[0]" :key="data.id" />
            </div>
          </div>
          <div class="middle">
            <section class="brief">
              <p class="name-en">{{ data.name_en }}</p>
              <p class="name-mandarin">{{ data.name_m }}</p>
            </section>
            <section class="details">
              <div class="item-row">
                <span class="title">Price</span>
                <span class="detail-content">${{ data.price }}</span>
              </div>
              <div class="item-row">
                <span class="title">Description</span>
                <span class="detail-content">{{ data.description }}</span>
              </div>
            </section>
          </div>
          <div class="deco">
            <img class="bar" src="@/images/backgrounds/border-deco-top.svg" />
            <img class="text" src="@/images/backgrounds/product-info.svg" />
            <img
              class="bar"
              src="@/images/backgrounds/border-deco-bottom.svg"
            />
          </div>
        </div>
        <img src="@/images/backgrounds/vector-bottom.png" />
        <div class="indicators">
          <button @click="toPrevious">
            <img src="@/images/icons/arrowLeft.svg" />
          </button>
          <button @click="toNext">
            <img src="@/images/icons/arrowRight.svg" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted } from "vue";
import Images from "./Images";
export default {
  name: "EachProductMoblie",
  components: { Images },
  props: {
    data: Object,
    currentIndex: Number,
    totalLength: Number,
    onClose: Function,
    toNext: Function,
    toPrevious: Function,
  },
};
</script>

<style lang="scss" scoped>
.each-product-lightbox.mobile {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.9);

  .box {
    width: 100%;
  }

  .numbers {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    & > .number {
      color: $white;
      font-family: "Red Rose";
      font-weight: 400;
      font-size: 35px;
      letter-spacing: 1px;
    }

    & > .dash {
      width: 36px;
      height: 1px;
      background-color: $white;
      margin: 0 6px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 15px;

    .main-content {
      flex: 1 1 auto;
      padding: 40px 30px 40px 0;
      overflow: scroll;
      position: relative;

      & > .top {
        padding-right: 30px;

        .image-wrapper {
          width: 100%;
          padding-bottom: calc(100% * 6 / 5);
          position: relative;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      & > .middle {
        margin-top: 20px;

        .brief {
          margin-bottom: 70px;
          padding-right: 30px;

          .name-en {
            font-family: "Red Rose";
            font-size: 24px;
            line-height: 30px;
            font-weight: 400;
            letter-spacing: 2px;
            color: $white;
            margin-bottom: 12px;
          }

          .name-mandarin {
            font-family: "Zpix";
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 2px;
            color: $white;
            margin-bottom: 0;
          }
        }

        .details {
          .item-row {
            display: flex;
            color: $white;
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 1px;

            & + .item-row {
              margin-top: 20px;
            }

            .title {
              flex: 0 0 130px;
              white-space: nowrap;
              font-family: "Red Rose";
            }

            .detail-content {
              flex: 1 1 auto;
              white-space: pre-line;
              font-family: "Zpix";
              letter-spacing: 1px;
              padding-left: 10px;
            }
          }
        }
      }

      & > .deco {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-self: stretch;
        position: absolute;
        top: 40px;
        right: 0;

        img.bar {
          height: 160px;
        }

        img.text {
          height: 80px;
          box-sizing: content-box;
          padding: 20px 0;
        }
      }
    }
  }

  .close-button {
    position: absolute;
    color: white;
    top: 70px;
    right: 70px;

    img {
      width: 100%;
    }

    @include RWD($tablet) {
      width: 50px;
      top: 10px;
      right: 20px;
    }
  }

  .indicators {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    margin-top: 15px;

    button {
      display: block;
      width: 15px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
