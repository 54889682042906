<script setup>
import { ref, watch } from "vue";
import useCarouselSlider from "./useCarouselSlider";

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  slideCount: {
    type: Number,
    default: 1,
  },
  perSlide: {
    type: Number,
    default: 1,
  },
  centerMode: {
    type: Boolean,
    default: false,
  },
  gap: {
    type: Number,
    default: 20,
  },
  carousel: {
    type: Number,
    default: 0,
  },
  enableIndicator: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["slide-changed"]);

const slider = ref(null);
const slideContainer = ref(null);
const { slideData, sliderStyle, currentSlide, nextSlide, changeSlide } =
  useCarouselSlider(slider, slideContainer, props);

watch(currentSlide, (idx) => {
  emit("slide-changed", idx);
});
</script>

<template>
  <div ref="slider" class="carousel-slider" :style="sliderStyle">
    <div class="slider-switch prev">
      <slot
        name="switch-prev"
        :prev="nextSlide.bind(null, -1)"
        :next="nextSlide.bind(null, 1)"
      >
        <button class="carousel-slider__btn prev" @click="nextSlide(-1)">
          Prev Slide
        </button>
      </slot>
    </div>
    <div class="slider">
      <div ref="slideContainer" class="slide-container">
        <div v-for="(slide, idx) of slideData" :key="idx" class="slide">
          <div
            v-for="(slideItem, itemIdx) of slide"
            :key="`item${itemIdx}`"
            class="slide-item"
          >
            <slot
              :data="slideItem"
              :active="slideItem._slide_id === currentSlide"
            ></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="slider-switch next">
      <slot
        name="switch-next"
        :prev="nextSlide.bind(null, -1)"
        :next="nextSlide.bind(null, 1)"
      >
        <button class="carousel-slider__btn next" @click="nextSlide(1)">
          Next Slide
        </button>
      </slot>
    </div>
    <div v-if="enableIndicator" class="slider-indicator">
      <slot name="indicator">
        <button
          v-for="(item, idx) of data"
          :key="idx"
          class="carousel-slider__indicator"
          :class="{ active: currentSlide === idx }"
          @click="changeSlide(idx)"
        >
          {{ idx + 1 }}
        </button>
      </slot>
    </div>
  </div>
</template>

<style lang="scss">
.carousel-slider {
  --switch-size: 30px;
  --slider-gap: 20px;
  display: grid;
  grid-template-columns: var(--switch-size) minmax(0, 1fr) var(--switch-size);
  grid-auto-rows: auto;
  gap: var(--slider-gap);
  place-items: center stretch;
  outline: none;

  .slider {
    overflow: hidden;
    margin: 0 calc(var(--gap) * -0.5);
    grid-row: 1 / 2;
    grid-column: 2 / 3;
  }

  .slider-indicator {
    display: grid;
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    grid-auto-flow: column;
    gap: 10px;
    place-content: center;
  }

  .slider-switch {
    &.prev {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }

    &.next {
      grid-row: 1 / 2;
      grid-column: 3 / 4;
    }
  }

  .slide-container {
    display: grid;
    grid-auto-columns: var(--slide-width);
    grid-auto-flow: column;
    transition: transform var(--transition-dur) ease-in-out;
    transform: translateX(var(--offset));
  }

  .slide {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: var(--gap);
    padding: 0 calc(var(--gap) * 0.5);
    /* padding: 0 var(--gap); */
  }

  &__btn {
    position: relative;
    width: var(--switch-size);
    height: 40px;
    font-size: 0;
    line-height: 0;

    &::after,
    &::before {
      position: absolute;
      right: 0;
      left: 0;
      margin: 0 auto;
      width: 8px;
      height: 50%;
      background-color: currentColor;
      content: "";
    }

    &::before {
      top: 0;
      transform: skewX(-30deg);
    }

    &::after {
      bottom: 0;
      transform: skewX(30deg);
    }

    &.next {
      transform: scaleX(-1);
    }
  }

  &__indicator {
    width: 10px;
    height: 10px;
    font-size: 0;
    line-height: 0;
    background-color: #eee;
    border-radius: 50%;
    transition: background 0.4s ease;

    &.active {
      background-color: #444;
    }
  }

  @media (max-width: 768px) {
    --slider-gap: 10px;
  }
}
</style>