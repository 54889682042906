<template>
  <MobileComponent
    v-if="media === 'mobile'"
    :dataList="dataList"
    :type="type"
    :autoPlaySec="autoPlaySec"
    :onClickCard="onClickCard"
  />
  <DesktopComponent
    v-else
    :dataList="dataList"
    :type="type"
    :autoPlaySec="autoPlaySec"
    :onClickCard="onClickCard"
  />
</template>

<script>
import { inject } from "vue";
import MobileComponent from "./mobile.vue";
import DesktopComponent from "./desktop.vue";
export default {
  name: "InfiniteCarousel",
  components: { MobileComponent, DesktopComponent },
  props: {
    dataList: Array,
    type: "product" | "event",
    autoPlaySec: Number,
    onClickCard: Function,
  },
  setup(props) {
    const { type = "event", autoPlaySec, onClickCard, dataList } = props;
    const media = inject("media");

    return {
      type,
      media,
      dataList,
      autoPlaySec,
      onClickCard,
    };
  },
};
</script>
