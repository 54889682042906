<template>
  <div ref="carousel" class="event-carousel">
    <CarouselSlider
      :class="['items', { [type]: true }]"
      :data="dataList"
      :slide-count="3"
      :gap="0"
      center-mode
    >
      <template #default="{ data, active }">
        <div
          class="item"
          :class="{ active }"
          @click="$emit('slide-clicked', data.id)"
        >
          <div class="image-wrapper">
            <img :src="data.image" draggable="false" />
          </div>
          <div class="info">
            <span>{{ data.name }}</span>
            <span>{{ data.name_en }}</span>
          </div>
        </div>
      </template>
    </CarouselSlider>
    <!-- <div class="items" :class="{ 'slide-active': turnOnSlide, [type]: true }">
      <div
        :key="item.id"
        v-for="item in renderList"
        class="item"
        @click="onClickCard(item.id)"
      >
        <div class="image-wrapper">
          <img :src="item.image" draggable="false" />
        </div>
        <div class="info">
          <span>{{ item.name }}</span>
          <span>{{ item.name_en }}</span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import CarouselSlider from "@/components/atoms/CarouselSlider";

export default {
  name: "InfiniteCarousel-Mobile",
  components: {
    CarouselSlider,
  },
  props: {
    dataList: Array,
    type: "product" | "event",
    autoPlaySec: Number,
    onClickCard: Function,
  },
  setup(props) {
    const { type = "event", autoPlaySec, onClickCard } = props;
    const currentIndex = ref(0);
    const carousel = ref(null);
    const windowWidth = ref(window.innerWidth);
    const list = ref(props.dataList);
    const turnOnSlide = ref(false);
    const interval = ref(null);

    const getIndex = (index, arrayLength) => {
      if (index < 0) {
        return arrayLength + index;
      }
      if (index > arrayLength - 1) {
        return index - arrayLength;
      }

      return index;
    };

    const renderList = computed(() => {
      let result = [];

      if (list.value.length === 0) return result;

      for (let i = -1; i < 3; i++) {
        const index = getIndex(currentIndex.value + i, list.value.length);
        result[i + 1] = list.value[getIndex(index)];
      }

      return result;
    });

    const cHeight = computed(() => {
      return (windowWidth.value * 0.72 * 2) / 3 + 100;
    });

    const autoPlay = () => {
      turnOnSlide.value = true;
    };

    const startAutoPlay = () => {
      interval.value = window.setInterval(() => {
        autoPlay();
      }, autoPlaySec);
    };

    const stopAutoPlay = () => {
      window.clearInterval(interval.value);
    };

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    // onMounted(() => {
    //   window.addEventListener("resize", handleResize);
    //   startAutoPlay();
    // });

    // onUnmounted(() => {
    //   window.removeEventListener("resize", handleResize);
    //   stopAutoPlay();
    // });

    watch(turnOnSlide, (t) => {
      let timeout;
      if (timeout) {
        clearTimeout(timeout);
      }

      if (t) {
        timeout = setTimeout(() => {
          currentIndex.value = getIndex(
            currentIndex.value + 1,
            list.value.length
          );
          turnOnSlide.value = false;
        }, 400);
      }
    });

    return {
      type,
      carousel,
      cHeight,
      renderList,
      turnOnSlide,
      currentIndex,
      onClickCard,
    };
  },
};
</script>

<style lang="scss" scoped>
.event-carousel {
  display: flex;
  justify-content: center;
  overflow: hidden;

  .carousel-slider {
    width: 250%;
    flex-shrink: 0;
  }

  .items {
    position: relative;
    height: 100%;

    .item {
      display: flex;
      flex-direction: column;
      margin: auto 0;
      cursor: pointer;
      transition: transform 200ms ease;

      &:not(.active) {
        transform: scale(0.7);

        .info {
          display: none;
        }
      }

      .image-wrapper {
        position: relative;
        width: 100%;
        height: 0;
        margin: auto 0;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &.event {
      gap: 6%;

      .item {
        position: relative;

        .image-wrapper {
          padding-bottom: calc(100% * 9 / 16);
        }

        .info {
          display: none;
        }
      }

      &.slide-active {
        transition: 0.4s left;
        left: calc(-137%);
      }
    }

    &.product {
      gap: 7%;
      align-items: flex-start;
      height: unset;

      .item {
        position: relative;

        .image-wrapper {
          padding-bottom: calc(100% * 2 / 3);
        }

        .info {
          // display: none;
          margin-top: 20px;
          width: 100%;
          top: 100%;

          & > span {
            display: block;
            color: $white;
            &:first-child {
              font-family: "Zpix";
              font-size: 14px;
              font-weight: 500;
              line-height: 14px;
              margin-bottom: 10px;
            }

            &:last-child {
              font-family: "Red Rose";
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.04em;
            }
          }
        }

        &:nth-child(2) {
          // flex: 0 0 72%;

          .info {
            display: block;
          }
        }
      }

      &.slide-active {
        transition: 0.4s left;
        left: calc(-112%);

        .item {
          transition: 0.4s flex;

          .info {
            display: none;
          }

          &:nth-child(2) {
            flex: 0 0 56%;
          }

          &:nth-child(3) {
            flex: 0 0 72%;

            .info {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>