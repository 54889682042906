import {
  ref, reactive, computed, toRef, onMounted
} from 'vue'
import {
  usePointerSwipe, useIntervalFn, useEventListener
} from '@vueuse/core'

export default function useCarouselSlider(
  sliderRef,
  slideContainerRef,
  options
) {
  const currentIdx = ref(0)
  const useClick = ref(true)
  const isSliding = ref(false)

  const centerFix = computed(() => (options.centerMode ? 1 : 0))
  const slideCount = computed(() =>
    options.centerMode
      ? options.slideCount + ((options.slideCount + 1) % 2)
      : options.slideCount
  )
  const perSlide = computed(() => (options.centerMode ? 1 : options.perSlide))
  const cloneSlideCount = computed(() => perSlide.value + 1 + centerFix.value)
  const currentSlide = computed(() => getSlideIndex(currentIdx.value))

  const slideData = computed(() => {
    // 無限輪播過一層資料處理
    const totalLength = lcm(perSlide.value, options.data.length)

    const sourceData = Array.from({ length: totalLength }, (o, idx) => {
      const index = getSlideIndex(idx)

      return {
        ...options.data[index],
        _id: index
      }
    })

    const dataArranged = sourceData.reduce((result, o, i) => {
      const index = Math.floor(i / perSlide.value)
      if (result[index]) {
        result[index].push({
          ...o, _slide_id: index
        })
      } else {
        result[index] = [{
          ...o, _slide_id: index
        }]
      }
      return result
    }, [])

    return getSlideData(dataArranged)
  })

  const ui = reactive({
    swipeOffset: 0,
    slideWidth: computed(() =>
      (100 / (slideCount.value / perSlide.value)).toFixed(2)
    ),
    sliderOffset: computed(
      () =>
        (currentIdx.value + cloneSlideCount.value - centerFix.value) *
        ui.slideWidth *
        -1
    ),
    transitionDur: computed(() => (isSliding.value ? 0.4 : 0)),
    sliderStyle: computed(() => {
      return {
        '--offset': `calc(${ui.sliderOffset}% + ${ui.swipeOffset}px)`,
        '--transition-dur': `${ui.transitionDur}s`,
        '--slide-width': `${ui.slideWidth}%`,
        '--gap': `${options.gap}px`
      }
    })
  })

  const { distanceX } = usePointerSwipe(sliderRef, {
    onSwipeStart() {
      // reset status
      useClick.value = true
    },
    onSwipe(e) {
      ui.swipeOffset = distanceX.value * -1

      if (Math.abs(distanceX.value) > 10) {
        useClick.value = false
      }
    },
    onSwipeEnd(e, direction) {
      ui.swipeOffset = 0
      nextSlide(direction === 'LEFT' ? 1 : -1)
    }
  })

  // carousel
  const {
    pause: pauseCarousel, resume: resumeCarousel
  } = useIntervalFn(
    () => {
      nextSlide(1)
    },
    4000,
    { immediate: Boolean(options.carousel) }
  )

  // bind events
  useEventListener(sliderRef, 'click', captureClick, { capture: true })
  useEventListener(sliderRef, 'focus', triggerCarousel, { capture: true })
  useEventListener(sliderRef, 'blur', triggerCarousel, { capture: true })
  useEventListener(slideContainerRef, 'transitionend', onSlideEnd)

  onMounted(() => {
    sliderRef.value.setAttribute('tabindex', 0)
  })

  function getSlideData(sourceData) {
    const prepend = Array.from({ length: cloneSlideCount.value }, (o, idx) => {
      const index = getSlideIndex(
        sourceData.length - cloneSlideCount.value + idx,
        sourceData.length
      )
      return sourceData[index]
    })
    const append = Array.from({ length: cloneSlideCount.value }, (o, idx) => {
      const index = getSlideIndex(idx, sourceData.length)
      return sourceData[getSlideIndex(index)]
    })

    return prepend.concat(sourceData).concat(append)
  }

  function changeSlide(id) {
    if (isSliding.value) return
    isSliding.value = true
    currentIdx.value = id
  }

  function nextSlide(dir) {
    changeSlide(currentIdx.value + dir)
  }

  function onSlideEnd(e) {
    if (e.target !== e.currentTarget) return

    isSliding.value = false
    currentIdx.value = getSlideIndex(currentIdx.value)
  }

  function captureClick(e) {
    if (!useClick.value) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  function getSlideIndex(idx, sourceLength = options.data.length) {
    return (idx + sourceLength) % sourceLength
  }

  function triggerCarousel(e) {
    if (!options.carousel) return

    if (e.type === 'focus') {
      pauseCarousel()
    } else {
      resumeCarousel()
    }
  }

  function validateActiveSlide(data) {
    return data._slide_id === currentSlide.value
  }

  return {
    currentSlide,
    slideData,
    sliderStyle: toRef(ui, 'sliderStyle'),
    pauseCarousel,
    resumeCarousel,
    changeSlide,
    nextSlide,
    validateActiveSlide
  }
}

function gcd(m, n) {
  if (n) return gcd(n, m % n)
  return m
}

function lcm(m, n) {
  return (m * n) / gcd(m, n)
}