<template>
  <teleport to="#portal-target">
    <div v-if="media === 'desktop'" class="each-product-lightbox desktop">
      <div class="box">
        <button @click="onClose" class="close-button">
          <img src="@/images/icons/close-icon.svg" />
        </button>
        <div class="content">
          <button @click="toPrevious">
            <img src="@/images/icons/arrowLeft.svg" />
          </button>
          <div class="main-content">
            <section class="numbers">
              <span class="number">{{
                currentIndex + 1 > 9 ? currentIndex : `0${currentIndex + 1}`
              }}</span>
              <span class="dash" />
              <span class="number">{{
                totalLength > 9 ? totalLength : `0${totalLength}`
              }}</span>
            </section>
            <div class="left">
              <Images :images="data.images" />
            </div>
            <div class="middle">
              <section class="brief">
                <p class="name-en">{{ data.name_en }}</p>
                <p class="name-mandarin">{{ data.name }}</p>
              </section>
              <section class="details">
                <div class="item-row">
                  <span class="title">Price</span>
                  <span class="detail-content">${{ data.price }}</span>
                </div>
                <div class="item-row">
                  <span class="title">Description</span>
                  <span class="detail-content">{{ data.description }}</span>
                </div>
              </section>
            </div>
            <div class="right">
              <img class="bar" src="@/images/backgrounds/border-deco-top.svg" />
              <img class="text" src="@/images/backgrounds/product-info.svg" />
              <img
                class="bar"
                src="@/images/backgrounds/border-deco-bottom.svg"
              />
            </div>
          </div>
          <button @click="toNext">
            <img src="@/images/icons/arrowRight.svg" />
          </button>
        </div>
      </div>
    </div>
    <Mobile
      v-else
      :data="data"
      :currentIndex="currentIndex"
      :totalLength="totalLength"
      :onClose="onClose"
      :toNext="toNext"
      :toPrevious="toPrevious"
    />
  </teleport>
</template>

<script>
import { computed, inject, onMounted, onUnmounted, toRef, toRefs } from "vue";
import Images from "./Images";
import Mobile from "./Mobile";
export default {
  name: "EachProduct",
  components: { Images, Mobile },
  props: {
    currentIndex: Number,
    totalLength: Number,
    onClose: Function,
    toNext: Function,
    toPrevious: Function,
    productData: Object,
  },
  setup(props) {
    const media = inject("media");
    const currentIndex = computed(() => {
      return Number(props.currentIndex);
    });

    const data = toRef(props, "productData");

    onMounted(() => {
      document.body.style.overflow = "hidden";
    });

    onUnmounted(() => {
      document.body.style.overflow = "auto";
    });

    return {
      currentIndex,
      data,
      media,
    };
  },
};
</script>

<style lang="scss" scoped>
.each-product-lightbox.desktop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.9);
  overflow: scroll;

  .box {
    display: flex;
    flex-direction: column;
  }

  .numbers {
    display: flex;
    align-items: center;
    position: absolute;
    top: -60px;
    left: 0;

    & > .number {
      color: $white;
      font-family: "Red Rose";
      font-weight: 400;
      font-size: 35px;
      letter-spacing: 1px;
    }

    & > .dash {
      width: 36px;
      height: 1px;
      background-color: $white;
      margin: 0 6px;
    }
  }

  .content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    margin: auto;
    padding: 70px 0;

    .main-content {
      display: flex;
      background-image: url(../../../images/backgrounds/lightbox-background-desktop.png);
      background-size: 100% 100%;
      padding: 15px;
      margin: 0 70px;
      background-color: $black;
      align-items: center;
      width: 1500px;
      max-width: 80vw;
      position: relative;

      & > .left {
        flex: 1 1 43%;
        margin-right: 100px;
      }

      & > .middle {
        flex: 1 1 57%;
        margin-right: 100px;

        .brief {
          margin-bottom: 70px;

          .name-en {
            font-family: "Red Rose";
            font-size: 40px;
            line-height: 40px;
            font-weight: 400;
            letter-spacing: 0.04em;
            color: $white;
            margin-bottom: 20px;

            @include RWD($mediumLaptop) {
              font-size: 34px;
              line-height: 34px;
            }
          }

          .name-mandarin {
            font-family: "Zpix";
            font-size: 26px;
            line-height: 32px;
            letter-spacing: 0.04em;
            color: $white;

            @include RWD($mediumLaptop) {
              font-size: 20px;
              line-height: 26px;
            }
          }
        }

        .details {
          .item-row {
            display: flex;
            color: $white;
            font-size: 21px;
            font-weight: 400;
            line-height: 40px;
            letter-spacing: 0.04em;

            @include RWD($mediumLaptop) {
              font-size: 15px;
              line-height: 30px;
            }

            & + .item-row {
              margin-top: 20px;
            }

            .title {
              flex: 0 0 200px;
              white-space: nowrap;
              font-family: "Red Rose";
            }

            .detail-content {
              flex: 1 1 auto;
              white-space: pre-line;
              font-family: "Zpix";
            }
          }
        }
      }

      & > .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-self: stretch;
        position: relative;

        img.bar {
          flex: 0 0 auto;
          height: 250px;
        }

        img.text {
          flex: 0 0 auto;
          height: 117px;
        }

        @include RWD($mediumLaptop) {
          img.bar {
            flex: 0 0 auto;
            height: 200px;
          }

          img.text {
            flex: 0 0 auto;
            height: 90px;
          }
        }
      }
    }
  }

  .close-button {
    width: 70px;
    height: 70px;
    color: white;
    margin: 30px 30px 0 auto;

    img {
      width: 100%;
    }

    @include RWD($mediumLaptop) {
      width: 60px;
      height: 60px;
    }
  }
}
</style>
